var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2024-11-13-18"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/vendor";

import * as Sentry from "@sentry/nextjs";

const { sentryBaseConfig } = require("@novel/shared/utils/sentryBaseConfig");

Sentry.init({
    ...sentryBaseConfig,
    dsn:
        process.env.SENTRY_DSN ||
        process.env.NEXT_PUBLIC_SENTRY_DSN ||
        "https://0e53126d1faf4c029a13448d1e641bd4@o1158538.ingest.sentry.io/6317313",
    project: "vendor-portal",
});
