import {
    ICryptoAddressesReducerState,
    walletsReducer,
} from "@vendor-portal/redux/reducers/cryptoAddresses";
import {
    customerRelationsReducer,
    ICustomersReducerState,
} from "@vendor-portal/redux/reducers/customers";
import {
    customerTagsReducer,
    ICustomerTagsReducerState,
} from "@vendor-portal/redux/reducers/customerTags";
import { discountsReducer, IDiscountReducerState } from "@vendor-portal/redux/reducers/discounts";
import {
    IGatedExperiencesReducerState,
    gatedExperiencesReducer,
} from "@vendor-portal/redux/reducers/gatedExperiences";
import {
    gatedLineItemsReducer,
    IGatedLineItemsReducerState,
} from "@vendor-portal/redux/reducers/gatedLineItems";
import {
    IIndividualTokenTransactionsReducerState,
    individualTokenTransactionsReducer,
} from "@vendor-portal/redux/reducers/individualTokenTransactions";
import { ILinkReducerState, linkReducer } from "@vendor-portal/redux/reducers/links";
import {
    ILinksAnalyticsReducerState,
    linksAnalyticsReducer,
} from "@vendor-portal/redux/reducers/links/linkAnalytics";
import { IOrdersReducerState, ordersReducer } from "@vendor-portal/redux/reducers/orders";
import {
    IOrganizationsReducerState,
    organizationsReducer,
} from "@vendor-portal/redux/reducers/organizations";
import {
    IOrganizationsDropdownReducerState,
    organizationsDropdownReducer,
} from "@vendor-portal/redux/reducers/organizations/dropdown";
import {
    IOrganizationsIntegrationsReducerState,
    organizationsIntegrationsReducer,
} from "@vendor-portal/redux/reducers/organizations/integrations";
import { IPaymasterReducerState, paymasterReducer } from "@vendor-portal/redux/reducers/paymaster";
import {
    IPriceRulesReducerState,
    priceRulesReducer,
} from "@vendor-portal/redux/reducers/priceRules";
import { IProductsReducerState, productsReducer } from "@vendor-portal/redux/reducers/products";
import {
    IPassPushNotificationReducerState,
    pushNotificationReducer,
} from "@vendor-portal/redux/reducers/pushNotifications";
import {
    IPassPushNotificationAnalyticsReducerState,
    pushNotificationAnalyticsReducer,
} from "@vendor-portal/redux/reducers/pushNotifications/pushNotificationAnalytics";
import {
    IRewardsMembersReducerState,
    rewardsMembersReducer,
} from "@vendor-portal/redux/reducers/rewardsMembers";
import {
    IRewardsTabReducerState,
    rewardsTabReducer,
} from "@vendor-portal/redux/reducers/rewardsTab";
import { ISiteUrlsReducerState, siteUrlsReducer } from "@vendor-portal/redux/reducers/siteUrls";
import { ISyncLogsReducerState, syncLogsReducer } from "@vendor-portal/redux/reducers/syncLogs";
import {
    ITokenClaimsReducerState,
    tokenClaimsReducer,
} from "@vendor-portal/redux/reducers/tokenClaims";
import {
    ITokenCollectionsReducerState,
    tokenCollectionsReducer,
} from "@vendor-portal/redux/reducers/tokenCollections";
import {
    ITokenMetadatasReducerState,
    tokenMetadatasReducer,
} from "@vendor-portal/redux/reducers/tokenMetadatas";
import {
    ITraitAttributesReducerState,
    tokenAttributeReducer,
} from "@vendor-portal/redux/reducers/traitAttributes";
import {
    ITransactionsReducerState,
    transactionsReducer,
} from "@vendor-portal/redux/reducers/transactions";
import {
    NovelVendorAction,
    NovelVendorActionActionType,
} from "@vendor-portal/redux/vendorTypedRedux";
import { combineReducers } from "redux";

import { authReducer, IAuthReducerState } from "./auth";
import { IGeneratorsReducerState, generatorsReducer } from "./generators";
import { IShopifyStoreDataReducerState, shopifyStoreDataReducer } from "./shopifyShopData";
import { IVariantsReducerState, variantsReducer } from "./variants";
import {
    IVendorOrganizationsReducerState,
    vendorOrganizationsReducer,
} from "./vendorUserOrganizations";
import {
    IOrgLocationsReducerState,
    orgLocationsReducer,
} from "@vendor-portal/redux/reducers/orgLocations";
import {
    ILocationCampaignsReducerState,
    locationCampaignsReducer,
} from "@vendor-portal/redux/reducers/locationCampaigns";
import { apiTokensReducer, IApiTokensReducerState } from "@vendor-portal/redux/reducers/apiTokens";
import { IPassWelcomeState, passStepReducer } from "@vendor-portal/redux/reducers/passWelcome";
import {
    IRewardsSourceTagsReducerState,
    rewardsSourceTagsReducer,
} from "@vendor-portal/redux/reducers/rewardsSourceTags";
import { tiersReducer, ITiersState } from "@vendor-portal/redux/reducers/tiers";
import {
    eligibleMembersReducer,
    IEligibleMembersState,
} from "@vendor-portal/redux/reducers/eligibleMembers";
import { IRetailOfferReducerState, retailOfferReducer } from "@vendor-portal/redux/reducers/retail";

const reducersObj = {
    vendorDiscounts: discountsReducer,
    auth: authReducer,
    apiTokens: apiTokensReducer,
    passWelcomeStep: passStepReducer,
    tiers: tiersReducer,
    eligibleMembers: eligibleMembersReducer,
    orders: ordersReducer,
    customers: customerRelationsReducer,
    pushNotifications: pushNotificationReducer,
    pushNotificationAnalytics: pushNotificationAnalyticsReducer,
    retailOffers: retailOfferReducer,
    links: linkReducer,
    linkAnalytics: linksAnalyticsReducer,
    locationCampaigns: locationCampaignsReducer,
    organizations: organizationsReducer,
    organizationsDropdown: organizationsDropdownReducer,
    transactions: transactionsReducer,
    individualTokenTransactions: individualTokenTransactionsReducer,
    tokenCollections: tokenCollectionsReducer,
    gatedExperiences: gatedExperiencesReducer,
    rewardsMembers: rewardsMembersReducer,
    rewardsSourceTags: rewardsSourceTagsReducer,
    orgLocations: orgLocationsReducer,
    rewardsTab: rewardsTabReducer,
    gatedLineItems: gatedLineItemsReducer,
    siteUrls: siteUrlsReducer,
    customerTags: customerTagsReducer,
    products: productsReducer,
    priceRules: priceRulesReducer,
    tokenClaims: tokenClaimsReducer,
    tokenMetadatas: tokenMetadatasReducer,
    tokenAttributes: tokenAttributeReducer,
    generators: generatorsReducer,
    vendorOrganizations: vendorOrganizationsReducer,
    shopifyStoreData: shopifyStoreDataReducer,
    variants: variantsReducer,
    syncLogs: syncLogsReducer,
    paymaster: paymasterReducer,
    addresses: walletsReducer,
    integrations: organizationsIntegrationsReducer,
};

// object ref ensures equality check failure
const impossibleToEqual = {};

// default values for each reducer
export const initialState: INovelState = Object.keys(reducersObj).reduce(
    (accum: any, key: string) => {
        accum[key] = (reducersObj as any)[key](void 0, {
            type: impossibleToEqual,
        });
        return accum;
    },
    {},
) as any;

export const rootReducer = combineReducers<
    INovelState,
    NovelVendorAction<NovelVendorActionActionType>
>(reducersObj);

export interface INovelState {
    vendorDiscounts: IDiscountReducerState;
    auth: IAuthReducerState;
    passWelcomeStep: IPassWelcomeState;
    tiers: ITiersState;
    eligibleMembers: IEligibleMembersState;
    apiTokens: IApiTokensReducerState;
    orders: IOrdersReducerState;
    customers: ICustomersReducerState;
    pushNotifications: IPassPushNotificationReducerState;
    pushNotificationAnalytics: IPassPushNotificationAnalyticsReducerState;
    retailOffers: IRetailOfferReducerState;
    links: ILinkReducerState;
    linkAnalytics: ILinksAnalyticsReducerState;
    organizations: IOrganizationsReducerState;
    organizationsDropdown: IOrganizationsDropdownReducerState;
    transactions: ITransactionsReducerState;
    individualTokenTransactions: IIndividualTokenTransactionsReducerState;
    tokenCollections: ITokenCollectionsReducerState;
    gatedExperiences: IGatedExperiencesReducerState;
    rewardsMembers: IRewardsMembersReducerState;
    rewardsSourceTags: IRewardsSourceTagsReducerState;
    locationCampaigns: ILocationCampaignsReducerState;
    orgLocations: IOrgLocationsReducerState;
    rewardsTab: IRewardsTabReducerState;
    gatedLineItems: IGatedLineItemsReducerState;
    siteUrls: ISiteUrlsReducerState;
    priceRules: IPriceRulesReducerState;
    customerTags: ICustomerTagsReducerState;
    products: IProductsReducerState;
    tokenClaims: ITokenClaimsReducerState;
    tokenMetadatas: ITokenMetadatasReducerState;
    tokenAttributes: ITraitAttributesReducerState;
    generators: IGeneratorsReducerState;
    vendorOrganizations: IVendorOrganizationsReducerState;
    shopifyStoreData: IShopifyStoreDataReducerState;
    variants: IVariantsReducerState;
    syncLogs: ISyncLogsReducerState;
    paymaster: IPaymasterReducerState;
    addresses: ICryptoAddressesReducerState;
    integrations: IOrganizationsIntegrationsReducerState;
}
